const idPortalApi = "ff6141b7005b282bad3de743783f1da9f4343040";

module.exports = {
  tokenLink: idPortalApi,
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  link: "http://www.transparenciadministrativa.com.br",
  urlADM: `https://administracaopublica.com.br`,
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem",
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/",

  // urlApi: `http://localhost:8004/${idPortalApi}`,
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  urlEmDeploy: "https://cmgoncalvesdias.ma.gov.br/", //usada para link de compartilhamento
  dnsPortal: "cmgoncalvesdias.ma.gov.br",
  NomePortal: "Gonçalves Dias",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true,
  countNewsHome: 6, //quantidade de noticias na home
  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario
  corIcones: "#b28e08",

  //links externos
  link_youtube: `#`,
  link_localize: `https://maps.app.goo.gl/Q4w1RTJ3M3CQHyRn7`,
  //Mapa da pagina Municipio/Mapa
  link_mapa:
    "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15894.699511424114!2d-44.309998089485326!3d-5.155914296172551!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x788fc74689b1b9b%3A0x7c1b8bb57f92e5cd!2sGon%C3%A7alves%20Dias%2C%20State%20of%20Maranh%C3%A3o%2C%2065775-000!5e0!3m2!1sen!2sbr!4v1721669996856!5m2!1sen!2sbr",
};
